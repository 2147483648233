const locale = {
  APPLICATIONS: 'Applications',
  DASHBOARDS: 'Dashboards',
  APPOINTMENT: 'Appointment',
  AGENDA: 'Agenda',
  USERS: 'users',
  DIETETIQUE: 'dietetiques',
  PATIENTS: 'Dossiers patients',
  MESSAGEPATIENTS: 'Messagerie patients',
  LOGIN: 'Login',
  WELLBESETTING: 'WellBe Setting',
  EXAMPLE: 'Example',
};

export default locale;
