import React, { useState, useEffect } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
  FormControl,
  TextField,
  InputAdornment,
  Button,
  Icon,
  IconButton,
  Divider,
} from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../store/actions';

/** Form Validation Schema * */
const schema = yup.object().shape({
  oldPassword: yup.string().required('Entrez votre mot de passe.'),
  password: yup
    .string()
    .required('Entrez votre mot de passe.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Le mot de passe doit contenir 8 caractères minimum, 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial'
    ),
  // .min(8, 'Le mot de passe est trop court - doit comporter au moins 8 caractères.'),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Les mots de passe doivent correspondre'),
});

const defaultFormState = {
  idUser: '',
  idProfil: '',
  idOrganization: '',
  idJobSpecialist: '',
  active: 'Y',
  organization: '',
  username: '',
  lastName: '',
  firstName: '',
  email: '',
  job: '',
  role: '',
  phoneNumber: '',
  professionalNumber: '',
  contactPhone: '',
  contactEmail: '',
  photoURL: '',
  avatar: '',
  oldPassword: '',
  password: '',
  passwordConfirm: '',
};

const Content = (props) => {
  console.log('🚀 ~ file: Content ~ props:', props);
  const dispatch = useDispatch();

  const { inUser, opNewPwd, setOpNewPwd } = props;
  console.log('🚀 ~ file: Content ~ inUser:', inUser);

  // TODO: Check this API Calls return "Action non valide" - PLS
  const userInfo = useSelector(({ profileApp }) => profileApp.profile.user);
  console.log('🚀 ~ file: Content ~ userInfo:', userInfo);

  const [showPassword, setShowPassword] = React.useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const methods = useForm({
    defaultValues: defaultFormState,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { reset, formState, handleSubmit, watch, control, setValue } = methods;
  const { errors } = formState;

  const form = watch();
  const password = watch('password');
  const passwordConfirm = watch('passwordConfirm');

  useEffect(() => {
    if (inUser && !_.isEmpty(inUser)) {
      reset({
        ...defaultFormState,
        ...inUser,
        ...userInfo,
      });
    }
  }, [reset, inUser, userInfo]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  function canBeSubmitted() {
    // return form.newPassword.length > 0;
    return !_.isEmpty(password) && !_.isEmpty(passwordConfirm);
  }

  async function handleReset() {
    setValue('passwordConfirm', '');
    setValue('password', '');
    setValue('oldPassword', '');
    // reset({ ...inUser });
    setOpNewPwd(!opNewPwd);
  }

  function onSubmit() {
    const userUpdate = {
      Iduser: form.idUser,
      idOrganization: form.idOrganization,
      username: form.username,
      oldPassword: form.oldPassword,
      password: form.password,
      passwordConfirm: form.passwordConfirm,
    };
    dispatch(Actions.changePassword(userUpdate));
    setOpNewPwd(!opNewPwd);
    setValue('passwordConfirm', '');
    setValue('password', '');
    setValue('oldPassword', '');
  }

  console.log('🚀 ~ file: Content ~ form:', form);
  console.log('🚀 ~ file: 117 ~ canBeSubmitted:', canBeSubmitted());
  return (
    <>
      <div className="mb-16">
        <div className="flex items-center justify-between p-12" />
        <Divider className="mx-24" />
      </div>

      <div className="px-16 sm:px-24">
        <div className="flex">
          <FormControl className="mt-8 mb-16 mr-8" fullWidth>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nom"
                  name="lastName"
                  id="lastName"
                  variant="filled"
                  disabled
                  type="text"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl className="mt-8 mb-16 mr-8" fullWidth>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Prénom"
                  id="firstName"
                  name="firstName"
                  variant="filled"
                  disabled
                  type="text"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </FormControl>
        </div>

        <div className="flex">
          <FormControl className="mt-8 mb-16 mr-8" fullWidth>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  id="email"
                  name="email"
                  variant="filled"
                  disabled
                  type="text"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl className="mt-8 mb-16 mr-8" fullWidth>
            <Controller
              name="job"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Spécialité/Profession"
                  id="job"
                  name="job"
                  variant="filled"
                  disabled
                  type="text"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </FormControl>
        </div>

        <div className="flex">
          <FormControl className="mt-8 mb-16 mr-8" fullWidth>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Téléphone"
                  id="phoneNumber"
                  name="phoneNumber"
                  variant="filled"
                  disabled
                  type="text"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl className="mt-8 mb-16 mr-8" fullWidth>
            <Controller
              name="professionalNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Téléphone professionnel"
                  id="professionalNumber"
                  name="professionalNumber"
                  variant="filled"
                  disabled
                  type="text"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </FormControl>
        </div>

        <div className="flex">
          <FormControl className="mt-8 mb-16 mr-8" fullWidth>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Identifiant"
                  name="username"
                  id="username"
                  variant="filled"
                  disabled
                  type="text"
                  fullWidth
                />
              )}
            />
          </FormControl>

          <FormControl className="mt-8 mb-16 mr-8" required fullWidth>
            <Controller
              name="oldPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Password"
                  name="oldPassword"
                  id="oldPassword"
                  placeholder={form.fact}
                  variant={opNewPwd ? 'filled' : 'outlined'}
                  disabled={opNewPwd}
                  type="text"
                  fullWidth
                />
              )}
            />
          </FormControl>
        </div>
        {!opNewPwd && (
          <>
            <div className="flex">
              <FormControl className="mt-8 mb-16 mr-8" required fullWidth>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="password"
                      label="Nouveau Mot de passe"
                      name="password"
                      fullWidth
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl className="mt-8 mb-16 mr-8" required fullWidth>
                <Controller
                  name="passwordConfirm"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Confirmation Nouveau mot de passe"
                      id="passwordConfirm"
                      name="passwordConfirm"
                      variant="outlined"
                      autoFocus
                      fullWidth
                      error={!!errors.passwordConfirm}
                      helperText={errors?.passwordConfirm?.message}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <div className="flex justify-between">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                disabled={!canBeSubmitted()}
              >
                Enregistrer
              </Button>
              <IconButton
                className="min-w-auto"
                onClick={() => {
                  if (window.confirm('êtes vous sur de vouloir réinitialiser?')) {
                    handleReset();
                  }
                }}
              >
                <Icon>cancel-presentation</Icon>
              </IconButton>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Content;
