import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import API from '@pi/api'; // call api url
import jwt from 'jsonwebtoken';
import _ from '@lodash';
import history from '@history';
/* eslint-disable camelcase */

/* *** config  *** */
const jwtConfig = {
  secret: 'app-dosteor-api-fonctionality',
  expiresIn: '60 minute', // A numeric value is interpreted as a seconds count. If you use a string be sure you provide the time units (days, hours, minute, etc)
};

class ApiJwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Invalid access_token');
            this.setSession(null);
            window.localStorage.clear();
            history.push({
              pathname: '/login',
            });
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access token expired');
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post('/api/auth/register', data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      API.post('/api/user', {
        action: 'login',
        Username: email,
        Password: password,
      }).then((response) => {
        if (response.data.success && !_.isEmpty(response.data.data.access_token)) {
          delete response.data.password;

          const UUID = FuseUtils.generateGUID();
          const secretToken = response.data.data.access_token;
          const tokenUser = secretToken || jwtConfig.secret;
          const userUUID = response.data.id ? `${UUID}_${response.data.id}` : `userUUID_${UUID}`;

          const access_token = jwt.sign({ id: userUUID }, tokenUser, {
            expiresIn: jwtConfig.expiresIn,
          });

          const user = {
            uuid: UUID,
            token: secretToken,
            id: response.data.id,
            role: response.data.role,
            idOrganization: response.data.data.idOrganization,
            data: response.data.data,
          };

          this.setSession(access_token);
          resolve(user);
        } else {
          reject(response.data);
        }
      });
    });
  };

  signInWithToken = () => {
    const access_token = this.getAccessToken();
    const decoded = jwtDecode(access_token);
    const userUID = decoded.id;
    const userID = userUID.split('_');

    return new Promise((resolve, reject) => {
      API.post('/api/user', {
        action: 'read',
        Iduser: userID[1],
      })
        .then((response) => {
          if (response.data.success && !_.isEmpty(response.data.data)) {
            const { idUser } = response.data.data;
            const userUUID = idUser ? `${userID[0]}_${idUser}` : `userUUID_${userID[0]}`;

            const updatedToken = jwt.sign({ id: userUUID }, jwtConfig.secret, {
              expiresIn: jwtConfig.expiresIn,
            });

            const user = {
              uuid: userID[0],
              token: updatedToken,
              id: idUser,
              role: response.data.data.idRole,
              idOrganization: response.data.data.idOrganization,
              data: response.data.data,
            };

            this.setSession(updatedToken);
            resolve(user);
          } else {
            this.logout();
            reject(new Error('Failed to login with token.'));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  updateUserData = (user) => {
    return axios.post('/api/auth/user/update', {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }

    const decoded = jwtDecode(access_token);

    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new ApiJwtService();

export default instance;
