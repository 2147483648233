// import _ from '@lodash';
// import * as Actions from '../actions';

// const initialState = {
// 	praticienlist: [],
// 	praticien: {},
// 	patients: {},
// 	appointmentDates: [],
// 	dateTmer: [],
// 	time: '',
// 	date: '',
// 	datetime: '',
// 	idPraticien: '',
// 	available: false,
// 	success: false,
// 	datereserve: '',
// 	labelsDialogOpen: false
// };

// const appointmentReducer = (state = initialState, action) => {
// 	switch (action.type) {
// 		case Actions.GET_PRATICIENS: {
// 			return {
// 				...state,
// 				praticienlist: action.payload.data
// 			};
// 		}
// 		case Actions.GET_PATIENTS: {
// 			return {
// 				...state,
// 				patients: action.payload.data
// 			};
// 		}
// 		case Actions.CREATE_PATIENT: {
// 			return {
// 				...state,
// 				// success: action.success,
// 				idPatientCreate: action.id
// 				// patient: action.patient,
// 				// entities: { ...action.payload }
// 			};
// 		}
// 		case Actions.PATIENTS_DIALOG_CLOSE: {
// 			return {
// 				...state,
// 				labelsDialogOpen: false
// 			};
// 		}
// 		case Actions.PATIENTS_DIALOG_OPEN: {
// 			return {
// 				...state,
// 				patientsDialogOpen: true
// 			};
// 		}
// 		case Actions.GET_PRATICIEN_APPOINTMENT: {
// 			// PLS - CONVERT ARRAY STRING TO ARRAY NUMBER
// 			const arrStr = action.payload.data;
// 			// const nuevo = arrStr.map(i => Number(i));
// 			// const toNumber = arrStr.map(i => _.toNumber(i));
// 			return {
// 				...state,
// 				appointmentDates: action.payload.data,
// 				praticien: action.praticien,
// 				idPraticien: action.idPraticien
// 			};
// 		}
// 		case Actions.GET_PRATICIEN_APPOINTMENT_RESET: {
// 			// PLS - CONVERT ARRAY STRING TO ARRAY NUMBER
// 			return {
// 				...state,
// 				praticien: {},
// 				patients: {},
// 				appointmentDates: [],
// 				dateTmer: [],
// 				time: '',
// 				date: '',
// 				datetime: '',
// 				idPraticien: '',
// 				available: false,
// 				success: false,
// 				datereserve: ''
// 			};
// 		}
// 		case Actions.GET_TIME: {
// 			return {
// 				...state,
// 				dateTmer: action.payload.data
// 			};
// 		}
// 		case Actions.SET_TIME: {
// 			return {
// 				...state,
// 				available: action.payload.available,
// 				success: action.payload.success,
// 				time: action.time,
// 				date: action.date,
// 				datetime: action.datetime
// 			};
// 		}
// 		case Actions.RESET_TIME: {
// 			return {
// 				...state,
// 				time: '',
// 				date: '',
// 				datetime: ''
// 			};
// 		}
// 		case Actions.SAVE_APPOINTMENT: {
// 			return {
// 				...state,
// 				datereserve: action.payload.status,
// 				success: action.payload.success
// 			};
// 		}
// 		case Actions.RESET_APPOINTMENT:
// 			return { ...initialState };
// 		default: {
// 			return state;
// 		}
// 	}
// };

// export default appointmentReducer;

import _ from '@lodash';
import * as Actions from '../actions';

const initialState = {
	praticienlist: [],
	praticien: {},
	patients: {},
	appointmentDates: [],
	dateTmer: [],
	time: '',
	date: '',
	datetime: '',
	idPraticien: '',
	available: false,
	success: false,
	datereserve: '',
	labelsDialogOpen: false,
	idConsultation: '',
	FormData: {
		idConsultation: '',
		idJobSpecialist: '',
		idOrganization: '',
		idPatient: '',
		Iduser: '',
		ConsultationNote: '',
		Size: '',
		Weight: '',
		Diagnostic: '',
		HealthCareCategoy: '',
		ConsultationDate: '',
		uploadFiles: [],
		dolorLocation: '',
		dolorIntensity: '',
		eradiation: '',
		triggeringFactor: '',
		treatmentName: '',
		startEradiationDate: '',
		analgesicTreatment: '',
		treatmentEfficacy: '',
		prevention1: '',
		prevention2: '',
		prevention3: '',
		pattern: '',
		physicalCapacity: '',
		riskyFoodsFrequency: '',
		breakfast: '',
		lunch: '',
		dinner: '',
		snack: '',
		dislikes: ''
	}
};

const appointmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_PATIENTS: {
			return {
				...state,
				patients: action.payload.data
			};
		}
		case Actions.CREATE_PATIENT: {
			return {
				...state,
				// success: action.success,
				idPatientCreate: action.id
				// patient: action.patient,
				// entities: { ...action.payload }
			};
		}
		case Actions.ADD_CONSULTATION: {
			return {
				...state,
				idConsultation: action.payload.idConsultation,
				entities: action.payload
			};
		}
		case Actions.UPDATE_FORM_DATA: {
			return {
				...state,
				FormData: action.payload
			};
		}
		default: {
			return state;
		}
	}
};

export default appointmentReducer;
