import AdminConfig from './admin/AdminConfig';
import Articles from './articles/ArticlesConfig';
import BodyPartsConfig from './bodyparts/BodyPartsConfig';
import CategoriesConfig from './categories/CategoriesConfig';
import EquipmentsConfig from './equipments/EquipmentsConfig';
import ExerciceConfig from './exercices/ExerciceConfig';
import FormulesConfig from './formules/FormulesConfig';
import GenderConfig from './gender/GenderConfig';
import LevelsConfig from './levels/LevelsConfig';
import NewsPapers from './newspapers/NewspapersConfig';
import ObjectifsConfig from './objectif/ObjectifsConfig';
import PolicyConfig from './policy/PolicyConfig';
import ProgramDietetique from './dietetique-program/DietetiquesConfig';
import ProgramSportif from './sportif-programs/SportifProgramsConfig';
import typeOfProgramsConfig from './typeOfPrograms/TypeOfProgramsConfig';

const AdministrationConfig = [
  AdminConfig,
  Articles,
  BodyPartsConfig,
  CategoriesConfig,
  EquipmentsConfig,
  ExerciceConfig,
  FormulesConfig,
  GenderConfig,
  LevelsConfig,
  NewsPapers,
  ObjectifsConfig,
  PolicyConfig,
  ProgramDietetique,
  ProgramSportif,
  typeOfProgramsConfig,
];

export default AdministrationConfig;
