// eslint-disable-next-line import/prefer-default-export
export const chats = [
  {
    id: 'ff6bc7f1-449a-4419-af62-b89ce6cae0aa',
    contactId: '9d3f0e7f-dcbd-4e56-a5e8-87b8154e9edf',
    unreadCount: 2,
    muted: false,
    lastMessage: 'See you tomorrow!',
    lastMessageAt: '2022-01-05T15:56:48.732Z',
  },
  {
    id: '4459a3f0-b65e-4df2-8c37-6ec72fcc4b31',
    contactId: '16b9e696-ea95-4dd8-86c4-3caf705a1dc6',
    unreadCount: 0,
    muted: false,
    lastMessage: 'See you tomorrow!',
    lastMessageAt: '2022-01-05T15:56:48.732Z',
  },
  {
    id: 'f73a5a34-a723-4b35-8439-5289e0164c83',
    contactId: 'bf172879-423a-4fd6-8df3-6d1938bbfe1f',
    unreadCount: 1,
    muted: false,
    lastMessage: 'See you tomorrow!',
    lastMessageAt: '2022-01-05T15:56:48.732Z',
  },
  {
    id: '747f101c-0371-4ca3-9f20-cb913a80fe89',
    contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
    unreadCount: 0,
    muted: true,
    lastMessage: 'See you tomorrow!',
    lastMessageAt: '2022-01-05T15:56:48.732Z',
  },
  {
    id: 'b3facfc4-dfc2-4ac2-b55d-cb70b3e68419',
    contactId: '6519600a-5eaa-45f8-8bed-c46fddb3b26a',
    unreadCount: 0,
    muted: false,
    lastMessage: 'See you tomorrow!',
    lastMessageAt: '2022-01-05T15:56:48.732Z',
  },
];
