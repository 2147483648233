import i18next from 'i18next';
import React from 'react';
import { authRoles } from 'app/auth';
import { Redirect } from 'react-router-dom';

import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'appointment', en);
i18next.addResourceBundle('fr', 'appointment', fr);

const AppointmentConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.appointment, // ['RH', 'AD', 'PT']
	routes: [
		{
			path: '/appointment',
			component: React.lazy(() => import('./Appointment'))
		}
		// {
		// 	path: '/appointment',
		// 	component: () => <Redirect to="/appointment/0" />
		// }
	]
};

export default AppointmentConfig;
