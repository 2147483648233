import { Hidden, Icon, IconButton, Typography } from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    backgroundColor: amber['700'],
    '&.active': {
      backgroundColor: '#2b5b86',
      border: 'solid 1px #373e4a',
    },
    '&:hover': {
      backgroundColor: '#2b5b86',
      border: 'solid 1px #373e4a',
    },
  },
}));

function Header(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { title, icon } = props;

  return (
    <div className="flex flex-1 p-8 relative">
      <div className="flex flex-1">
        <div className="flex">
          <div className="flex items-center">
            <motion.div
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
            >
              <Typography className="flex items-center" color="inherit" variant="h6">
                <Icon className="text-32 mr-12">{icon}</Icon>
                <span className="flex mx-4 font-medium">{title}</span>
              </Typography>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
