import FuseAnimate from '@fuse/core/FuseAnimate';
import FusePageSimple from '@fuse/core/FusePageSimple';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Avatar from '@material-ui/core/Avatar';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import _ from '@lodash';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import AuthRolesTitles from '@dosteor/utils/AuthRolesTitles';
import Header from './incs/Header';
import Content from './incs/Content';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import 'moment/locale/fr';

const useStyles = makeStyles((theme) => ({
  layoutsRoot: {
    display: 'flex',
    overflow: 'hidden',
    scrollbarGutter: 0,
  },
  toolbar: { display: 'flex' },
  header: {
    color: '#ffffff',
    height: '70px',
    minHeight: '60px',
    background: theme.palette.background.headerToolbar,
    backgroundSize: 'cover',
  },
  contentRoot: {
    maxHeight: '100%',
    height: '100%',
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Profile() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const moment = extendMoment(Moment);
  moment.locale('fr');

  const userInfo = useSelector(({ auth }) => auth.user);

  const [infoUser, setInfoUser] = React.useState({});
  const [checked, setChecked] = React.useState(true);
  const [opNewPwd, setOpNewPwd] = React.useState(true);

  React.useEffect(() => {
    if (userInfo && !_.isEmpty(userInfo)) {
      setInfoUser(userInfo.data);
      dispatch(Actions.getUserProfile(userInfo.id, userInfo.idOrganization));
    }
  }, [dispatch, userInfo]);

  return (
    <FusePageSimple
      classes={{
        root: classes.layoutRoot,
        content: 'flex',
        header: clsx(classes.header, 'flex mt-10'),
        toolbar: clsx(classes.toolbar, 'mt-10 h-160'),
      }}
      header={<Header title="Profil utilisateur" icon="assignmentInd" />}
      contentToolbar={
        <div className="p-36 flex flex-1 flex-col items-center justify-center md:flex-row md:items-end">
          <div className="flex flex-1 flex-col items-center justify-center md:flex-row md:items-center md:justify-start">
            <FuseAnimate animation="transition.expandIn" delay={300}>
              <Avatar className="w-96 h-96" src="assets/images/avatars/Velazquez.jpg" />
            </FuseAnimate>
            <div className="flex flex-1 flex-col">
              <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <Typography className="md:mx-24" variant="h4" color="inherit">
                  {userInfo.data.displayName}
                </Typography>
              </FuseAnimate>
              <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <Typography
                  className="text-16 font-medium capitalize ml-24 px-5"
                  color="textSecondary"
                >
                  {!_.isEmpty(userInfo.job) ? (
                    userInfo.job
                  ) : (
                    <AuthRolesTitles status={userInfo.role} />
                  )}
                </Typography>
              </FuseAnimate>
            </div>
          </div>

          <div className="flex items-center justify-end">
            <div className="flex flex-col">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      disabled
                      checked
                      name="Active"
                      inputProps={{ 'aria-label': 'active' }}
                    />
                  }
                  label="Utilisateur Active"
                  labelPlacement="end"
                />
              </FormGroup>
              <Button
                className="normal-case mt-16 mr-5"
                variant="contained"
                color="primary"
                aria-label="changer le mot de passe"
                onClick={() => setOpNewPwd(!opNewPwd)}
              >
                changer le mot de passe
              </Button>
            </div>
          </div>
        </div>
      }
      content={
        <div className="p-24 w-full">
          <Content inUser={infoUser} opNewPwd={opNewPwd} setOpNewPwd={setOpNewPwd} />
        </div>
      }
    />
  );
}

export default withReducer('profileApp', reducer)(Profile);
