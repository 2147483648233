import i18next from 'i18next';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

import en from './i18n/en';
import tr from './i18n/tr';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'policy', en);
i18next.addResourceBundle('tr', 'policy', tr);
i18next.addResourceBundle('fr', 'policy', fr);
 
const PolicyConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  // auth: authRoles.admin, // ['admin']
  routes: [
    {
      path: '/policy',
      component: React.lazy(() => import('./Policy')), 
    },
  ],
};

export default PolicyConfig;
 
