import i18next from 'i18next';
import Profile from './Profile';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';

i18next.addResourceBundle('en', 'profile', en);
i18next.addResourceBundle('tr', 'profile', tr);
i18next.addResourceBundle('ar', 'profile', ar);

const ProfileConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/profile',
      component: Profile,
    },
  ],
};

export default ProfileConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default ExampleConfig;

*/
