import React from 'react';
import { authRoles } from 'app/auth';

const UserAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user, // ['SA', 'PT', 'AD', 'RH', 'PA', 'PG']
  routes: [
    {
      path: '/user',
      component: React.lazy(() => import('./UserApp')),
    },
  ],
};

export default UserAppConfig;
