import QuickPanel from 'app/fuse-layouts/layout4/components/quickPanel/QuickPanel';
import { memo } from 'react';

function RightSideLayout4(props) {
  return (
    <>
      <QuickPanel />
    </>
  );
}

export default memo(RightSideLayout4);
