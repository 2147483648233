import i18next from 'i18next';
import React from 'react';
import { authRoles } from 'app/auth';
import { Redirect } from 'react-router-dom';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'preferences', en);
i18next.addResourceBundle('fr', 'preferences', fr);

const PreferencesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.preferences, // ['SA', 'AD', 'RH', 'PA', 'PR', 'PG', 'DE']
  routes: [
    {
      path: '/preferences/:id',
      component: React.lazy(() => import('./Preferences')),
    },
    {
      path: '/preferences',
      component: () => <Redirect to="/preferences/0" />,
    },
  ],
};

export default PreferencesConfig;
