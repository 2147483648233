export const heading = {
  options: [
    {
      model: 'paragraph',
      title: 'Paragraphe',
      class: 'ck-heading_paragraph',
    },
    {
      model: 'heading1',
      view: 'h1',
      title: 'Grand titre',
      class: 'ck-heading_heading1',
    },
    {
      model: 'heading2',
      view: 'h2',
      title: 'Moyen titre',
      class: 'ck-heading_heading2',
    },
    {
      model: 'heading3',
      view: 'h3',
      title: 'Petit titre',
      class: 'ck-heading_heading2',
    },
  ],
};

export const toolbar = {
  items: [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    'blockQuote',
    'undo',
    'redo',
    'insertTable',
    'outdent',
    'indent',
  ],
};
