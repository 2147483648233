import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Consultations from './Consultation.js';
import * as Actions from '../store/actions';
import { Button, IconButton } from '@material-ui/core';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import BrandingWatermarkOutlinedIcon from '@material-ui/icons/BrandingWatermarkOutlined';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import { authRoles } from 'app/auth';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { selectChatPanelState } from './chatPanel/store/stateSlice';
import _ from 'lodash';
// import { IconButton } from '@material-ui/core';
const ConsultationBar = () => {
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const login = useSelector(({ auth }) => auth.login);
	const user = useSelector(({ auth }) => auth.user);
	const [isFullScreen, setIsFullScreen] = useState(false);
	const userRole = useSelector(({ auth }) => auth.user.role);
	const [width, setWidth] = useState('1/2');
	const state = useSelector(selectChatPanelState);
	useEffect(() => {
		if (user && user.id) dispatch(Actions.getPatients(user.id, user.idOrganization));
	}, [login, user]);
	return (login && login.success && !state && _.includes(authRoles.dps, userRole)) ||
		(user && user.token && !state && _.includes(authRoles.dps, userRole)) ? (
		isOpen ? (
			<div
				className={`fixed w-full max-w-full z-9999 bottom-0 right-0 h-${isFullScreen ? 'full' : '3/4'} max-h-${
					isFullScreen ? 'full' : '3/4'
				} w-full  md:h-${isFullScreen ? 'full' : '3/4'} overflow-x-hidden md:max-h-${
					isFullScreen ? 'full' : '3/4'
				} md:w-${
					isFullScreen ? '4/5' : '1/2'
				} bg-white flex justify-center flex-col items-center  border-solid border-t-2 border-l-2 border-grey`}
			>
				<div className="relative min-w-full w-full items-center flex justify-center text-center h-60 min-h-60 bg-cyan">
					<div className="absolute  h-full w-full left-5 flex items-center justify-between">
						<IconButton variant="outlined" onClick={() => setIsFullScreen(!isFullScreen)}>
							{isFullScreen ? <BrandingWatermarkOutlinedIcon /> : <AspectRatioIcon />}
						</IconButton>
						<IconButton variant="outlined" onClick={() => setIsOpen(false)}>
							<ArrowDropDownCircleIcon />
						</IconButton>
					</div>
					<div>
						<h6 className="text-white font-normal text-xl">Enregistrer une consultation</h6>
					</div>
				</div>
				<div className="relative max-w-10/12 m-auto h-3/4 max-h-3/4 overflow-auto">
					<Consultations setIsOpen={setIsOpen} />
				</div>
				<div className="w-full flex justify-end">
					<div
						onClick={() => setIsOpen(!isOpen)}
						className="fixed bottom-0 right-0 h-48 w-100 min-w-100 md:w-150 md:min-w-150 bg-blue flex justify-center items-center cursor-pointer text-center"
					>
						<span className="font-bold text-md text-white">Consultation</span>
					</div>
				</div>
			</div>
		) : (
			<div className="fixed z-9999999999999 bottom-0 right-0 overflow-x-hidden flex justify-center flex-col items-end cursor-pointer">
				<div
					onClick={() => setIsOpen(!isOpen)}
					className="bottom-0 right-0 h-48 w-100 bg-cyan flex min-w-100 md:w-150 md:min-w-150 justify-end items-center cursor-pointer text-center"
				>
					<span className="font-bold text-md text-white w-full text-center">Consultation</span>
				</div>
			</div>
		)
	) : null;
};

export default ConsultationBar;
