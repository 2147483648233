import _ from '@lodash';
import * as Actions from '../actions';

const initialState = {
  entities: {},
  user: {},
  userDialog: {
    type: 'edit',
    props: {
      open: false,
    },
    data: null,
  },
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_USER_PROFILE: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case Actions.UPDATE_USER_PROFILE: {
      return {
        ...state,
        entities: action.payload,
      };
    }
    case Actions.OPEN_PROFILE_DIALOG: {
      return {
        ...state,
        userDialog: {
          type: 'edit',
          props: {
            open: true,
          },
          data: action.data,
        },
      };
    }
    case Actions.CLOSE_PROFILE_DIALOG: {
      return {
        ...state,
        userDialog: {
          type: 'edit',
          props: {
            open: false,
          },
          data: null,
        },
      };
    }
    default:
      return state;
  }
};

export default profileReducer;
