import i18next from 'i18next';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import React, { lazy } from 'react';

const LevelsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  // auth: authRoles.admin, // ['admin']
  routes: [
    {
      path: '/levels',
      component: React.lazy(() => import('./Levels')),
    },
  ],
};

export default LevelsConfig;
