// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import selectedItem from './selectedItem.reducer';
import appointment from './appointment.reducer';
import messaging from './messagings.reducer';

const reducer = combineReducers({
	appointment,
	selectedItem,
	messaging
});

export default reducer;
