import React from 'react';

function AuthRolesTitles(props) {
  console.log("🚀 ~ file: 4 ~ AuthRolesTitles ~ props:", props)
  switch (props.status) {
    case 'SA':
      return 'Super Administrateur';
    case 'PA':
      return 'Praticien administrateur';
    case 'PG':
      return 'Praticien Gestionnaire';
    case 'PR':
      return 'Praticien';
    case 'AD':
      return 'Gestionnaire';
    case 'RH':
      return 'Ressources Humaines';
    case 'PT':
      return 'Patient';
    case 'US':
      return 'Utilisateur';
    case 'RE': // staff
      return 'Maintenance';
    case 'DE': // staff
      return 'Developer';
    default:
      return 'US';
  }
}

export default AuthRolesTitles;
