// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';

import profile from './profile.reducer';

const reducer = combineReducers({
  profile,
});

export default reducer;
