import i18next from 'i18next';
import React from 'react';
import { authRoles } from 'app/auth';
import { Redirect } from 'react-router-dom';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'preferences', en);
i18next.addResourceBundle('fr', 'preferences', fr);

const PreferencesConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.editor, // ['SA', 'AD', 'RH', 'PA', 'PR', 'PG']
	routes: [
		{
			path: '/contact',
			component: React.lazy(() => import('./Contact'))
		}
	]
};

export default PreferencesConfig;
