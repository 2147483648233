import i18next from 'i18next';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { authRoles } from 'app/auth';

import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'agenda', en);
i18next.addResourceBundle('fr', 'agenda', fr);

const AgendaConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.agenda, // ['SA', 'AD', 'RH', 'PA', 'PR', 'PG']
  routes: [
    {
      path: '/agenda/:agendaHandle/:agendaUri?',
      component: React.lazy(() => import('./rdv')),
    },
    {
      path: '/agenda',
      component: React.lazy(() => import('./Agenda')),
    },
    {
      path: '/agenda',
      component: () => <Redirect to="/agenda" />,
    },
  ],
};

export default AgendaConfig;
