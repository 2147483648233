import i18next from 'i18next';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'patientsPage', en);
i18next.addResourceBundle('fr', 'patientsPage', fr);

const PatientsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.dps, // ['SA', 'PT', 'PA', 'PR', 'PG']
  routes: [
    {
      path: '/patient/:patientHandle/:idPatient?/:consultId?',
      component: React.lazy(() => import('./patient/Index')),
    },
    {
      path: '/patients/list',
      component: React.lazy(() => import('./patients/Index')),
    },
    {
      path: '/patients',
      component: () => <Redirect to="/patients/list" />,
    },
    // {
    // 	path: '/patient/pdf',
    // 	component: React.lazy(() => import('./dashboard/PDFAnalytics'))
    // },
    // {
    // 	path: 'patient/:id/dietetiques/menu',
    // 	component: React.lazy(() => import('./patient/Dietetiques'))
    // },
    // {
    // 	path: 'patient/:id/dietetiques',
    // 	component: () => <Redirect to="dietetiques/menu" />
    // },
    // {
    // 	path: 'patient/:id/dietetiques/menu/:detailDate',
    // 	component: React.lazy(() => import('./dietetiques/MenuDetail'))
    // }
  ],
};

export default PatientsConfig;
