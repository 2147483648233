import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ContactsIcon from '@material-ui/icons/Contacts';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import _ from 'lodash';
import { authRoles } from 'app/auth';
import AuthRolesTitles from '@dosteor/utils/AuthRolesTitles';
import { logoutUser } from 'app/auth/store/userSlice';

const useStyles = makeStyles((theme) => ({
  avatar: {
    background: theme.palette.background.default,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  avatarinit: {
    background: '#c89d2d',
  },
}));

function UserMenu(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector(({ auth }) => auth.user);
  const authUser = useSelector(({ auth }) => auth.user);
  const [userMenu, setUserMenu] = useState(null);

  const avatarImg =
    !_.isEmpty(user.data.photoURL) && user.data.photoURL !== 'NULL'
      ? user.data.photoURL
      : 'assets/images/avatars/profile.jpg';

  const initiales = user.data.displayName
    ? user.data.displayName.match(/\b\w/g).join('').toUpperCase()
    : '';

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <>
      <Button className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6" onClick={userMenuClick}>
        {!_.isEmpty(user.data.photoURL) && user.data.photoURL !== 'NULL' ? (
          <Avatar
            className={clsx(classes.avatar, 'user-avatar md:mx-4')}
            alt="user photo"
            src={`${avatarImg}`}
          />
        ) : (
          <Avatar
            className={clsx(classes.avatarinit, 'md:mx-4')}
          >{`${user.data.firstName[0]}${user.data.lastName[0]}`}</Avatar>
        )}
        <div className="flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold">
            {user.data.job}
          </Typography>
          <Typography className="text-11 font-medium capitalize" color="textSecondary">
            <AuthRolesTitles status={user.role.toString()} />
          </Typography>
        </div>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/login" role="button">
              <ListItemIcon className="min-w-40">
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText primary="Login" />
            </MenuItem>
            <MenuItem component={Link} to="/register" role="button">
              <ListItemIcon className="min-w-40">
                <Icon>person_add</Icon>
              </ListItemIcon>
              <ListItemText primary="Register" />
            </MenuItem>
          </>
        ) : (
          <>
            {/* (authUser.role === 'PA' || authUser.role === 'PR' || authUser.role === 'PG') && ( */}
            {!_.isEmpty(authUser.role) &&
              _.includes(authRoles.preferencesDietetiques, authUser.role) && (
                <Link
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  to="/preferences"
                  className="capitalize"
                  onClick={userMenuClose}
                >
                  <MenuItem>
                    <ListItemIcon className="min-w-40">
                      <MenuBookIcon />
                    </ListItemIcon>
                    <ListItemText primary="Préférences" />
                  </MenuItem>
                </Link>
              )}
            {!_.isEmpty(authUser.role) && (authUser.role === 'PA' || authUser.role === 'SA') && (
              <Link
                style={{ textDecoration: 'none', color: 'inherit' }}
                to="/support-chat"
                className="capitalize"
                onClick={userMenuClose}
              >
                <MenuItem>
                  <ListItemIcon className="min-w-40">
                    <ContactSupportIcon />
                  </ListItemIcon>
                  <ListItemText primary="Support" />
                </MenuItem>
              </Link>
            )}
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              to="/contact"
              className="capitalize"
              onClick={userMenuClose}
            >
              <MenuItem>
                <ListItemIcon className="min-w-40">
                  <ContactsIcon />
                </ListItemIcon>
                <ListItemText primary="Contact" />
              </MenuItem>
            </Link>
            {!_.isEmpty(authUser.role) && _.includes(authRoles.profil, authUser.role) && (
              <Link
                style={{ textDecoration: 'none', color: 'inherit' }}
                to="/profile"
                className="capitalize"
                onClick={userMenuClose}
              >
                <MenuItem>
                  <ListItemIcon className="min-w-40">
                    <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Profil" />
                </MenuItem>
              </Link>
            )}
            <MenuItem
              onClick={() => {
                dispatch(logoutUser());
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Déconnexion" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
