import LoginConfig from './login/LoginConfig'; 
import RegisterConfig from './register/RegisterConfig'; 
import ResetPasswordConfig from './reset-password/ResetPasswordConfig';
import ForgotPasswordConfig from './forgot-password/ForgotPasswordConfig';
import LockConfig from './lock/LockConfig';
import MailConfirmConfig from './mail-confirm/MailConfirmConfig'; 
import LogoutConfig from './logout/LogoutConfig';

const authConfigs = [
  LoginConfig,
  RegisterConfig,
  ResetPasswordConfig,
  ForgotPasswordConfig,
  MailConfirmConfig,
  LockConfig, 
  LogoutConfig
];

export default authConfigs;
