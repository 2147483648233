export const gregorian_fr_lowercase = {
	name: 'gregorian_en_lowercase',
	months: [
		['janvier', 'Jan'],
		['fevrier', 'fev'],
		['Mars', 'mar'],
		['avril', 'avr'],
		['mai', 'mai'],
		['juin', 'juin'],
		['juillet', 'juil'],
		['aout', 'aou'],
		['septembre', 'sep'],
		['octobre', 'oct'],
		['novembre', 'nov'],
		['decembre', 'dec']
	],
	weekDays: [
		['samedi', 'sam'],
		['dimanche', 'dim'],
		['lundi', 'lun'],
		['mardi', 'mar'],
		['mercredi', 'mer'],
		['jeudi', 'jeu'],
		['vendredi', 'ven']
	],
	digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
	meridiems: [
		['AM', 'am'],
		['PM', 'pm']
	]
};
