import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { makeStyles, createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { heading, toolbar } from './configCKEditor';
import { useDispatch } from 'react-redux';
import * as Actions from '../../store/actions';

const useStyles = makeStyles(theme => ({
	padding: {
		padding: theme.spacing(1)
	},
	margin: {
		marginTop: theme.spacing(2)
	}
}));

let theme = createTheme({
	typography: {
		htmlFontSize: 12,
		subtitle1: {
			fontSize: 10,
			fontStyle: 'italic'
		},
		h6: {
			fontWeight: 'bold'
		}
	}
});
theme = responsiveFontSizes(theme);

const CKEditorConsultation = ({ field, label, helpertext, name, getValues }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [editorLoaded, setEditorLoaded] = React.useState(false);

	React.useEffect(() => {
		setEditorLoaded(true);
	}, [editorLoaded]);

	return editorLoaded ? (
		<>
			<ThemeProvider theme={theme}>
				{label && (
					<Typography variant="h6" className={clsx(classes.margin, 'pb-5 mb-5')}>
						{label}
					</Typography>
				)}
				<CKEditor
					className="max-w-full"
					editor={ClassicEditor}
					data={field.value}
					default={field.default}
					onChange={(event, editor) => {
						field.onChange(
							editor.getData(),
							dispatch(
								Actions.updateFormData({
									...getValues(),
									[name]: editor.getData()
								})
							)
						);
					}}
					config={{
						heading,
						toolbar
					}}
				/>
				{helpertext && (
					<Typography variant="subtitle1" className={clsx(classes.padding, 'pt-5 mt-5')}>
						{helpertext}
					</Typography>
				)}
			</ThemeProvider>
		</>
	) : (
		<p>Chargement</p>
	);
};

export default CKEditorConsultation;
