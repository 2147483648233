/* eslint-disable no-restricted-syntax */
import { useRef, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
	FileUploadContainer,
	FormField,
	DragDropText,
	UploadFileBtn,
	FilePreviewContainer,
	ImagePreview,
	PreviewContainer,
	PreviewList,
	FileMetaData,
	// RemoveFileIcon,
	InputLabel
} from './uploadDragDrop.styles';

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 50000000;

const convertNestedObjectToArray = nestedObj => Object.keys(nestedObj).map(key => nestedObj[key]);

const convertBytesToKB = bytes => Math.round(bytes / KILO_BYTES_PER_BYTE);

const Input = props => <input type="file" accept="image/*" name="img-loader-input" multiple {...props} />;

const UploadDragDrop = ({
	labelInput,
	labelPreview,
	onChange,
	maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
	...otherProps
}) => {
	const fileInputField = useRef(null);
	const [files, setFiles] = useState({});

	const handleUploadBtnClick = () => {
		fileInputField.current.click();
	};

	const addNewFiles = newFiles => {
		for (const file of newFiles) {
			if (file.size < maxFileSizeInBytes) {
				if (!otherProps.multiple) {
					return { file };
				}
				files[file.name] = file;
			}
		}
		return { ...files };
	};

	// eslint-disable-next-line no-shadow
	const callUpdateFilesCb = files => {
		const filesAsArray = convertNestedObjectToArray(files);
		onChange(filesAsArray);
	};

	const handleNewFileUpload = e => {
		const { files: newFiles } = e.target;
		if (newFiles.length) {
			const updatedFiles = addNewFiles(newFiles);
			setFiles(updatedFiles);
			callUpdateFilesCb(updatedFiles);
		}
	};

	const removeFile = fileName => {
		delete files[fileName];
		setFiles({ ...files });
		callUpdateFilesCb({ ...files });
	};

	return (
		<>
			<FileUploadContainer>
				<InputLabel>{labelInput}</InputLabel>
				<DragDropText>Faites glisser et déposez vos fichiers ici</DragDropText>
				<UploadFileBtn type="button" onClick={handleUploadBtnClick}>
					<i className="fa-solid fa-file-arrow-up" />
					<span> Ajouter {otherProps.multiple ? 'des fichiers' : 'un fichier'}</span>
				</UploadFileBtn>
				<FormField
					type="file"
					ref={fileInputField}
					onChange={handleNewFileUpload}
					title=""
					value=""
					{...otherProps}
				/>
			</FileUploadContainer>
			<FilePreviewContainer>
				<span>{labelPreview}</span>
				<PreviewList>
					{Object.keys(files).map((fileName, index) => {
						const file = files[fileName];
						const isImageFile = file.type.split('/')[0] === 'image';
						return (
							<PreviewContainer key={fileName}>
								<div>
									{isImageFile && (
										<ImagePreview src={URL.createObjectURL(file)} alt={`file preview ${index}`} />
									)}
									<FileMetaData isImageFile={isImageFile}>
										<span>{file.name}</span>
										<aside>
											<span>{convertBytesToKB(file.size)} kb</span>
											<DeleteIcon
												// className="fas fa-trash-alt"
												onClick={() => removeFile(fileName)}
											/>
										</aside>
									</FileMetaData>
								</div>
							</PreviewContainer>
						);
					})}
				</PreviewList>
			</FilePreviewContainer>
		</>
	);
};

export default UploadDragDrop;
