import AgendaAppConfig from './agenda/AgendaConfig';
import DashboardConfig from './dashboard/DashboardConfig';
import DietetiquesConfig from './dietetiques/DietetiquesConfig';
import NotesAppConfig from './notes/NotesAppConfig';
import UserAppConfig from './user/UserAppConfig';
import PatientsConfig from './dossier-patients/PatientsConfig';
import AppointmentConfig from './appointment/AppointmentConfig';
import PreferencesConfig from './preferences/PreferencesConfig';
import OrganizationsConfig from './organizations/OrganizationsConfig';
import SupportConfig from './support-chat/SupportChatConfig';
import ContactConfig from './contact/ContactConfig';
import MessagingConfig from './messaging/chat/ChatAppConfig';
import ProfileConfig from './profile/ProfileConfig';
// import MessagingConfig from './messaging/MessagingConfig';

const appsConfigs = [
  AgendaAppConfig,
  DashboardConfig,
  DietetiquesConfig,
  NotesAppConfig,
  PatientsConfig,
  UserAppConfig,
  AppointmentConfig,
  PreferencesConfig,
  OrganizationsConfig,
  SupportConfig,
  ContactConfig,
  MessagingConfig, 
  ProfileConfig,
];

export default appsConfigs;
