import axios from 'axios';
import API from '@pi/api';

export const GET_APPOINTMENTS = '[CONSULT SCREEN] GET APPOINTMENTS';
export const GET_APPOINTMENT = '[CONSULT SCREEN] GET APPOINTMENT';
export const GET_CONSULTATION = '[CONSULT SCREEN] GET CONSULTATION';
export const SEND_MESSAGE = '[CONSULT SCREEN] SEND MESSAGE';
export const GET_MESSAGE = '[CONSULT SCREEN] GET MESSAGE';
export const SEND_NOTICE = '[CONSULT SCREEN] SEND NOTICE';
export const APPOINTMENT_VALIDITY = '[CONSULT SCREEN] APPOINTMENT VALIDITY';
export const NOTICE_PREPARE_RATE = '[CONSULT SCREEN] NOTICE PREPARE RATE';
export const CONSULTATION_TIME = '[CONSULT SCREEN] CONSULTATION TIME';
export const NOTICE_PREPARE_RECOMMEND = '[CONSULT SCREEN] NOTICE_PREPARE_RECOMMEND';
export const NOTICE_PREPARE_OPINION = '[CONSULT SCREEN] NOTICE PREPARE OPINION';

export function sendMessage(messageInfos) {
	return (dispatch, getState) => {
		const objectSend = {
			action: 'chatbot',
			idAppointment: '3',
			Sender: 'Praticien', //  ( envoi : Praticien ou Patient )
			Message: messageInfos.message,
			SubmitDateTime: messageInfos.date
		};
		const request = API.post('/api/mob/appointments', objectSend);

		return request.then(response =>
			Promise.all([
				dispatch({
					type: SEND_MESSAGE,
					payload: response.data
				})
			])
		);
	};
}

export function getMessage(messageInfos) {
	return (dispatch, getState) => {
		const objectSend = {
			action: 'chatMessages',
			idAppointment: '3',
			idCalendar: '186'
		};
		const request = API.post('/api/mob/appointments', objectSend);

		return request.then(response =>
			Promise.all([
				dispatch({
					type: GET_MESSAGE,
					payload: response.data
				})
			])
		);
	};
}

export function sendNotice() {
	return (dispatch, getState) => {
		const objectSend = {
			action: 'avis',
			idAppointment: '3',
			AviSendBy: '11',
			Duration: '30',
			Star: '4',
			PatientOpinion: 'tester commentaire',
			Recommend: 'Y'
		};
		const request = API.post('/api/mob/appointments', objectSend);

		return request.then(response =>
			Promise.all([
				dispatch({
					type: SEND_NOTICE,
					payload: response.data
				})
			])
		);
	};
}

export function appointmentValidity() {
	return (dispatch, getState) => {
		const objectSend = {
			action: 'appointment',
			Type: 'Visio',
			Status: 'Confirmer', // ( confirmer, annuler, ... )
			idSpecialist: 53,
			idPatient: '11',
			idCalendar: '186',
			Iduser: 17
		};
		const request = API.post('/api/calendar', objectSend);

		return request.then(response =>
			Promise.all([
				dispatch({
					type: APPOINTMENT_VALIDITY,
					payload: response.data
				})
			])
		);
	};
}
