import API from '@pi/api';
import { showMessage } from 'app/store/fuse/messageSlice';

export const GET_USER_PROFILE = '[PROFILE APP] GET USER PROFILE';
export const UPDATE_USER_PROFILE = '[PROFILE APP] UPDATE USER PROFILE';
export const OPEN_PROFILE_DIALOG = '[PROFILE APP] OPEN PROFILE DIALOG';
export const CLOSE_PROFILE_DIALOG = '[PROFILE APP] CLOSE PROFILE DIALOG';

export function getUserProfile(IdUser, idOrganization) {
  const request = API.post('/api/profil', { action: 'read', Iduser: IdUser, idOrganization });

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: GET_USER_PROFILE,
        payload: response.data.data,
      })
    );
}

export function changePassword(userUpdate) {
  return (dispatch, getState) => {
    const { id, idOrganization } = getState().auth.user;

    const request = API.post('/api/profil', {
      action: 'updatePassword',
      userUpdate,
    });

    return request.then((response) =>
      Promise.all([
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: response.data.data,
        }),
      ]).then(() => dispatch(getUserProfile(id, idOrganization)))
    );
  };
}

export function openProfileDialog(data) {
  return {
    type: OPEN_PROFILE_DIALOG,
    data,
  };
}

export function closeProfileDialog() {
  return {
    type: CLOSE_PROFILE_DIALOG,
  };
}
