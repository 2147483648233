/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import FuseUtils from '@fuse/utils/FuseUtils';
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Paper, Typography } from '@mui/material';
import Button from '@material-ui/core/Button';
import React from 'react';
import _ from '@lodash';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment/moment';
import 'moment/locale/fr';
import UploadDragDrop from 'app/shared-components/uploads/UploadDragDrop';
import CKEditorConsultation from './consultations/CKEditorConsultation';
import './consultations/patient.css';
import DietetiqueConsultation from './consultations/DietetiqueConsultation';
import PracticianConsultation from './consultations/PracticianConsultation';
import { showMessage } from 'app/store/fuse/messageSlice';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useLocation } from 'react-router-dom';
import { WindowScrollController } from '@fullcalendar/react';
import AutoComplet from './AutoComplet.js';
import * as Actions from '../store/actions';
import ComfirmPopUp from './consultations/incs/ComfirmPopUp';
// import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
	contentRoot: { display: 'flex' },
	btnReset: { backgroundColor: '#c89d2d' }
}));

// const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
// 	props,
// 	ref,
//   ) {
// 	return <MuiAlert elevation={6} ref={ref} elevation={6} variant="filled" {...props} />;
//   });

/** Form Validation Schema * */
const schema = yup.object().shape({
	// ConsultationNote: yup.string(),
	// Diagnostic: yup.string(),
	// HealthCareCategoy: yup.string(),
});

function Consultations({ idConsultationSelected, setIdConsultationSelected, setIsOpen }) {
	const theme = useTheme();
	const classes = useStyles();
	const dispatch = useDispatch();
	const routeParams = useParams();
	const [openConfirm, setOpenConfirm] = React.useState(false);
	const [patient, setPatient] = React.useState({
		name: '',
		idPatient: '',
		phonenumber: ''
	});
	// const idConsultation = useSelector(({ patientsApp }) => patientsApp.consultation.idConsultation);
	// const docts = useSelector(({ patientsApp }) => patientsApp.document.docs);
	const idUser = useSelector(({ auth }) => auth.user.id);
	const idOrganization = useSelector(({ auth }) => auth.user.idOrganization);
	const idJobSpecialist = useSelector(({ auth }) => auth.user.data.idJobSpecialist);
	const patients = useSelector(({ consultationGeneral }) => consultationGeneral.appointment.patients);
	const formData = useSelector(({ consultationGeneral }) => consultationGeneral.appointment.FormData);
	const { idPatient } = routeParams;

	const [viewScrollButton, setViewScrollButton] = React.useState(false);
	const [patientList, setPatientList] = React.useState([]);
	const [idSelected, setIdSelected] = React.useState('');
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

	const defaultValues = {
		idConsultation: FuseUtils.generateGUID(),
		idJobSpecialist,
		idOrganization,
		idPatient: patient.idPatient,
		Iduser: idUser,
		ConsultationNote: '',
		Size: '',
		Weight: '',
		Diagnostic: '',
		HealthCareCategoy: '',
		ConsultationDate: moment(new Date()).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
		uploadFiles: [],
		dolorLocation: 'Localisation de la douleur',
		dolorIntensity: 'Intensité de la douleur',
		eradiation: '',
		triggeringFactor: '',
		treatmentName: '',
		startEradiationDate: moment(new Date()).format('DD/MM/YYYY'),
		analgesicTreatment: 'Non renseigné',
		treatmentEfficacy: 'Non renseigné',
		prevention1: '',
		prevention2: '',
		prevention3: '',
		pattern: '',
		physicalCapacity: '',
		riskyFoodsFrequency: '',
		breakfast: '',
		lunch: '',
		dinner: '',
		snack: '',
		dislikes: ''
	};

	const patientInfos = {
		idPatient,
		Iduser: idUser,
		idOrganization,
		idJobSpeciality: idJobSpecialist
	};

	const [action, setAction] = React.useState('new');
	const [docs, setDocs] = React.useState(0);

	const { control, reset, handleSubmit, formState, getValues, watch, setValue } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});
	const { isValid, dirtyFields, errors, touchedFields } = formState;
	const isFormEdited = formState.touchedFields;
	const consultForm = watch();

	React.useEffect(() => {
		window.addEventListener(
			'scroll',
			() => {
				if (window.scrollY > 300) {
					setViewScrollButton(true);
				} else if (window.scrollY < 500) {
					setViewScrollButton(false);
				}
			},
			{ passive: true }
		);
		if (patients) {
			const newArray = patients.map(item => {
				return { name: item.FullName, idPatient: item.idPatient, phonenumber: item.PhoneNumber };
			});
			setPatientList(newArray);
		}
		if (idConsultationSelected !== null) {
			setValue('idConsultation', idConsultationSelected);
			setIdSelected(idConsultationSelected);
		}
		if (formData.Iduser) {
			reset({ ...formData });
		}
	}, [patients, setPatient]);

	React.useEffect(() => {
		setAction('new');
	}, []);

	const handlePatient = setPat => {
		const { name, idPatient, phonenumber } = setPat;

		const getPatient = {
			name,
			idPatient,
			phonenumber
		};
		setPatient(getPatient);
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	const updateUploadedFiles = files => reset({ ...consultForm, uploadFiles: files });

	async function onSubmit() {
		const object = {
			...getValues(),
			idPatient: patient.idPatient,
			idConsultation: getValues().idConsultation === undefined ? 'rlkfojshfjsodh' : 'hjksqhdgsqyuhfgu',
			startEradiationDate:
				moment(getValues().startEradiationDate).format('YYYY-MM-DD') !== 'Invalid date'
					? moment(getValues().startEradiationDate).format('YYYY-MM-DD')
					: moment(new Date()).format('YYYY-MM-DD'),
			idJobSpecialist: idJobSpecialist,
			ConsultationNote:
				getValues().ConsultationNote !== undefined || getValues().ConsultationNote !== null
					? getValues().ConsultationNote
					: '',
			Diagnostic:
				getValues().Diagnostic !== undefined || getValues().Diagnostic !== null ? getValues().Diagnostic : '',
			HealthCareCategoy:
				getValues().HealthCareCategoy !== undefined || getValues().HealthCareCategoy
					? getValues().HealthCareCategoy
					: ''
		};
		await dispatch(Actions.addConsultation(object));
		if (consultForm.uploadFiles && consultForm.uploadFiles.length > 0) {
			await dispatch(Actions.uploadDocuments(object.idConsultation, object));
		}
		await setSnackbarSeverity('success');
		await setSnackbarMessage('Consultation créer avec succès');
		await setOpenSnackbar(true);
		await setPatient({
			name: '',
			idPatient: '',
			phonenumber: ''
		});
		await onResetConsult();
	}

	const onResetConsult = () => {
		setAction('new');
		reset({
			...defaultValues,
			idConsultation: FuseUtils.generateGUID(),
			idPatient: idPatient || '',
			Iduser: idUser
		});
	};

	async function handleOpenConfirm() {
		await setOpenConfirm(true);
	}

	const calculateIMC = value => {
		const weight = value.Weight;
		const size = value.Size;
		const imc = (weight * 10000) / (size * size);
		return parseFloat(imc).toFixed(2);
	};
	return (
		<form
			name="consultationForm"
			noValidate
			className="w-full flex  flex-col justify-center"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className={clsx('flex pb-10 pt-2 ', action === 'edit' ? 'justify-between' : 'justify-end')}>
				<Button
					variant="contained"
					color="primary"
					className={clsx(classes.btnReset, action === 'edit' ? 'w-224 ml-10' : 'hidden')}
					aria-label="Reset"
					onClick={onResetConsult}
					type="button"
				>
					Nouvelle Consultation
				</Button>
				<Button
					variant="contained"
					color="primary"
					className="w-100 md:w-224 mr-10"
					aria-label="Register"
					disabled={_.isEmpty(dirtyFields) || !isValid}
					onClick={handleOpenConfirm}
				>
					Enregistrer
				</Button>
			</div>
			<Paper
				component={motion.div}
				initial={{ y: -20, opacity: 0 }}
				animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
				className={clsx(classes.contentRoot, 'flex flex-1 p-40 items-center max-w-full w-full shadow')}
				aria-label="Consultations"
			>
				<div className="flex flex-1 flex-col items-center">
					<div className="flex gap-14 pb-8">
						<Controller
							name="Size"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value } }) => (
								<TextField
									id="size"
									fullWidth
									variant="filled"
									label="Taille"
									value={value === null ? '' : value}
									onChange={e =>
										onChange(
											e.target.value,
											dispatch(Actions.updateFormData({ ...getValues(), Size: e.target.value }))
										)
									}
									InputProps={{
										endAdornment: <InputAdornment position="start">(cm)</InputAdornment>
									}}
									error={!!errors.Size}
								/>
							)}
						/>
						<Controller
							name="Weight"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									id="weight"
									fullWidth
									variant="filled"
									label="Poids"
									value={value === null ? '' : value}
									onChange={e =>
										onChange(
											e.target.value,
											dispatch(Actions.updateFormData({ ...getValues(), Weight: e.target.value }))
										)
									}
									InputProps={{
										endAdornment: <InputAdornment position="start">(Kg)</InputAdornment>
									}}
									error={!!errors.Weight}
								/>
							)}
						/>
					</div>
					<div>
						<Typography variant="h6" className="mb-16">
							IMC : {calculateIMC(getValues())}
						</Typography>
					</div>
					{idJobSpecialist === '1' ? (
						<PracticianConsultation control={control} watch={watch} getValues={getValues} />
					) : (
						<DietetiqueConsultation control={control} watch={watch} getValues={getValues} />
					)}
					<div className="CKEditor w-full">
						<Controller
							id="scroll_marker"
							name="ConsultationNote"
							defaultValue=""
							control={control}
							render={({ field }) => (
								<CKEditorConsultation
									field={field}
									label="Consultation"
									name="ConsultationNote"
									getValues={getValues}
								/>
							)}
						/>
					</div>
					<div className="CKEditor">
						<Controller
							className="max-w-full"
							name="Diagnostic"
							control={control}
							defaultValue=""
							render={({ field }) => (
								<CKEditorConsultation
									field={field}
									label="Diagnostic"
									name="Diagnostic"
									getValues={getValues}
								/>
							)}
						/>
					</div>
					<div className="CKEditor">
						<Controller
							className="max-w-full"
							name="HealthCareCategoy"
							defaultValue=""
							control={control}
							render={({ field }) => (
								<CKEditorConsultation
									field={field}
									label="Soins"
									name="HealthCareCategoy"
									getValues={getValues}
								/>
							)}
						/>
					</div>
					<div className="flex flex-col pt-8 w-full">
						{docs > 0 && (
							<div className="flex justify-end">
								<Button color="secondary">{docs} document(s) assigner à cette consultation</Button>
							</div>
						)}
						<UploadDragDrop
							accept=".jpg,.png,.jpeg,.pdf,.doc,.docx"
							labelInput="Téléverser fichier(s)"
							labelPreview="Fichier(s) ajouté"
							multiple
							onChange={files => (
								setValue(`uploadFiles`, files, { shouldDirty: true, shouldValidate: true }),
								dispatch(Actions.updateFormData({ ...getValues(), uploadFiles: files }))
							)}
						/>
					</div>
				</div>
			</Paper>
			<ComfirmPopUp
				openConfirm={openConfirm}
				patient={patient}
				setPatient={setPatient}
				patientList={patientList}
				setOpenConfirm={setOpenConfirm}
				onSubmit={onSubmit}
			/>
			<div className="w-full flex justify-center items-center">
				<Snackbar
					className="w-full flex justify-center items-center"
					open={openSnackbar}
					autoHideDuration={2000}
					onClose={() => setOpenSnackbar(false)}
				>
					<MuiAlert
						onClose={() => setOpenSnackbar(false)}
						severity={snackbarSeverity}
						className="w-3/4 md:w-1/3 m-auto flex justify-center items-center"
						sx={{ fontSize: '1.5rem' }}
						elevation={6}
						variant="filled"
					>
						{snackbarMessage}
					</MuiAlert>
				</Snackbar>
			</div>
		</form>
	);
}

export default Consultations;
