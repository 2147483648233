import i18next from 'i18next';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import React, { lazy } from 'react';

const EquipmentsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	// auth: authRoles.admin, // ['admin']
	routes: [
		{
			path: '/equipments',
			component: React.lazy(() => import('./Equipments'))
		}
	]
};

export default EquipmentsConfig;
