import { lazy } from 'react';
import Chat from './chat/Chat';
import ChatFirstScreen from './ChatFirstScreen';

// import ChatApp from './ChatApp';

const ChatAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/messaging',
      component: lazy(() => import('./ChatApp')),
      children: [
        {
          path: '',
          element: <ChatFirstScreen />,
        },
        {
          path: ':id',
          element: <Chat />,
        },
      ],
    },
  ],
  // routes: [
  // 	{
  // 		path: 'messaging',
  // 		element: <ChatApp />,
  // 		children: [
  // 			{
  // 				path: '',
  // 				element: <ChatFirstScreen />
  // 			},
  // 			{
  // 				path: ':id',
  // 				element: <Chat />
  // 			}
  // 		]
  // 	}
  // ]
};

export default ChatAppConfig;
