import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@mui/material';
import React from 'react';
import _ from '@lodash';
import { Controller, useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import FoodAutoComplete from './forms/FoodAutoComplete';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions';
import 'moment/locale/fr';
import './patient.css';

const useStyles = makeStyles(theme => ({
	contentRoot: { display: 'flex' },
	btnReset: { backgroundColor: '#c89d2d' }
}));

const mealInfo = [
	{ meal: 'breakfast', idMeal: 'B', pos: 1, color: 'bg-blue-100', title: 'Petit déjeuner' },
	{ meal: 'lunch', idMeal: 'L', pos: 2, color: 'bg-green-100', title: 'Déjeuner' },
	{ meal: 'dinner', idMeal: 'D', pos: 3, color: 'bg-red-100', title: 'Dinner' },
	{ meal: 'create', idMeal: 'C', pos: 'B', color: 'bg-blue-400', title: 'Création' }
];

function DietetiqueConsultation({ control, watch, getValues }) {
	const theme = useTheme();
	const dispatch = useDispatch();
	const routeParams = useParams();

	const idUser = useSelector(({ auth }) => auth.user.id);
	const { idPatient } = routeParams;
	const formData = useSelector(({ consultationGeneral }) => consultationGeneral.appointment.FormData);
	const [selectedValue, setSelectedValue] = React.useState(null);
	const [inputList, setInputList] = React.useState([]);
	const [data, setData] = React.useState([]);

	return (
		<ThemeProvider theme={theme}>
			<div className="w-full grid grid-cols-2 gap-20 items-center">
				<div className="w-full text-center">
					<Typography variant="h4" sx={{ margin: '25px 0' }}>
						Motif de prise en charge
					</Typography>
					<Controller
						name="pattern"
						control={control}
						render={({ field: { onChange, value } }) => (
							// <TextField
							// 	id="eradiation"
							// 	className="w-full"
							// 	variant="filled"
							// 	label="Motif"
							// 	value={value === null ? '' : value}
							// 	onChange={onChange}
							// />
							<FoodAutoComplete
								selectedValue={value}
								setSelectedValue={setSelectedValue}
								onChange={e =>
									onChange(
										e.target.value,
										dispatch(
											Actions.updateFormData({
												...getValues(),
												pattern: e.target.value
											})
										)
									)
								}
								setInputList={setInputList}
							/>
						)}
					/>
				</div>
				<div className="w-full text-center">
					<Typography variant="h4" sx={{ margin: '25px 0' }}>
						Activités physiques
					</Typography>
					<Controller
						name="physicalCapacity"
						control={control}
						render={({ field: { onChange, value } }) => (
							<TextField
								id="eradiation"
								className="w-full"
								variant="filled"
								label="Capacités et activités physiques"
								value={value === null ? '' : value}
								onChange={e =>
									onChange(
										e.target.value,
										dispatch(
											Actions.updateFormData({
												...getValues(),
												physicalCapacity: e.target.value
											})
										)
									)
								}
							/>
						)}
					/>
				</div>
			</div>
			<div className="w-full text-center">
				<Typography variant="h4" sx={{ margin: '25px 0' }}>
					Enquête alimentaire
				</Typography>
				<Controller
					name="dislikes"
					control={control}
					render={({ field: { onChange, value } }) => (
						<div>
							<TextField
								id="FamilyHistory"
								className="w-1/2"
								// fullWidth
								// multiline
								rows={4}
								value={value}
								onChange={e =>
									onChange(
										e.target.value,
										dispatch(
											Actions.updateFormData({
												...getValues(),
												dislikes: e.target.value
											})
										)
									)
								}
								variant="filled"
								label="Aversions"
							/>
						</div>
					)}
				/>
				<div className="w-full grid grid-cols-4 gap-20 my-20">
					<Controller
						name="breakfast"
						control={control}
						render={({ field: { onChange, value } }) => (
							<div>
								<TextField
									id="breakfast"
									fullWidth
									multiline
									rows={4}
									value={value}
									onChange={e =>
										onChange(
											e.target.value,
											dispatch(
												Actions.updateFormData({
													...getValues(),
													breakfast: e.target.value
												})
											)
										)
									}
									variant="filled"
									label="Petit déjeuner"
								/>
							</div>
						)}
					/>
					<Controller
						name="lunch"
						control={control}
						render={({ field: { onChange, value } }) => (
							<div>
								<TextField
									id="FamilyHistory"
									fullWidth
									multiline
									rows={4}
									value={value}
									onChange={e =>
										onChange(
											e.target.value,
											dispatch(
												Actions.updateFormData({
													...getValues(),
													lunch: e.target.value
												})
											)
										)
									}
									variant="filled"
									label="Déjeuner"
								/>
							</div>
						)}
					/>
					<Controller
						name="dinner"
						control={control}
						render={({ field: { onChange, value } }) => (
							<div>
								<TextField
									id="FamilyHistory"
									fullWidth
									multiline
									rows={4}
									value={value}
									onChange={e =>
										onChange(
											e.target.value,
											dispatch(
												Actions.updateFormData({
													...getValues(),
													dinner: e.target.value
												})
											)
										)
									}
									variant="filled"
									label="Diner"
								/>
							</div>
						)}
					/>
					<Controller
						name="snack"
						control={control}
						render={({ field: { onChange, value } }) => (
							<div>
								<TextField
									id="snack"
									fullWidth
									multiline
									rows={4}
									value={value}
									onChange={e =>
										onChange(
											e.target.value,
											dispatch(
												Actions.updateFormData({
													...getValues(),
													snack: e.target.value
												})
											)
										)
									}
									variant="filled"
									label="Collations"
								/>
							</div>
						)}
					/>
				</div>
				<Controller
					name="eradiation"
					control={control}
					render={({ field: { onChange, value } }) => (
						<TextField
							id="riskyFoodsFrequency"
							className="w-full"
							minRows={3}
							variant="filled"
							fullWidth
							multiline
							label="Autres"
							value={value === null ? '' : value}
							onChange={e =>
								onChange(
									e.target.value,
									dispatch(
										Actions.updateFormData({
											...getValues(),
											eradiation: e.target.value
										})
									)
								)
							}
						/>
					)}
				/>
			</div>
		</ThemeProvider>
	);
}

export default DietetiqueConsultation;
