// import layout1 from './layout1/Layout1Config';
// import layout2 from './layout2/Layout2Config';
// import layout3 from './layout3/Layout3Config';
import layout4 from './layout4/Layout4Config'; // PLS - Add Dosteor Theme

const FuseLayoutConfigs = {
  // layout1,
  // layout2,
  // layout3,
	layout4
};

export default FuseLayoutConfigs;
