import _ from '@lodash';
import * as Actions from '../actions';

const initialState = {
	idConsultation: 0,
	appointments: [],
	appointment: [],
	messsaages: [],
	registerNotice: {},
	notice: {
		Star: 0,
		PatientOpinion: '',
		Recommend: ''
	},
	timeOfConsultation: '',
	appointmentValidity: {}
};

const filesReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_CONSULTATION:
			return {
				...state,
				idConsultation: action.payload
			};
		case Actions.GET_APPOINTMENTS:
			return {
				...state,
				appointments: action.payload
			};
		case Actions.GET_APPOINTMENT:
			return {
				...state,
				appointment: action.payload
			};
		case Actions.SEND_MESSAGE:
			return {
				...state,
				messsages: action.payload
			};
		case Actions.GET_MESSAGE:
			return {
				...state,
				messsaages: action.payload
			};
		case Actions.SEND_NOTICE:
			return {
				...state,
				registerNotice: action.payload
			};
		case Actions.APPOINTMENT_VALIDITY:
			return {
				...state,
				appointmentValidity: action.payload
			};
		case Actions.CONSULTATION_TIME:
			return {
				...state,
				timeOfConsultation: action.payload
			};
		case Actions.NOTICE_PREPARE_RATE:
			return {
				...state,
				notice: {
					...state.notice,
					Star: action.payload
				}
			};
		case Actions.NOTICE_PREPARE_OPINION:
			return {
				...state,
				notice: {
					...state.notice,
					PatientOpinion: action.payload
				}
			};
		case Actions.NOTICE_PREPARE_RECOMMEND:
			return {
				...state,
				notice: {
					...state.notice,
					Recommend: action.payload
				}
			};
		default:
			return state;
	}
};

export default filesReducer;
