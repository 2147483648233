import appsConfigs from 'app/main/apps/appsConfigs';
import AdministrationConfig from 'app/main/administration/AdministrationConfig';
import authConfigs from 'app/main/auth/authConfigs';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import Error404Page from 'app/main/errors/404/Error404Page';
import { Redirect } from 'react-router-dom';

import FuseLoading from '@fuse/core/FuseLoading';
import FuseUtils from '@fuse/utils';

const routeConfigs = [...authConfigs, ...appsConfigs, ...pagesConfigs, ...AdministrationConfig];
// function setAdminAuth(configs) {
// 	return configs.map(config => _.merge({}, config, { auth: authRoles.user }));
// }

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['developer','staff']),
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: '/loading',
    exact: true,
    component: () => <FuseLoading />,
  },
  {
    path: '/404',
    component: () => <Error404Page />,
  },
  {
    path: '/*',
    component: () => <Error404Page />,
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

export default routes;
