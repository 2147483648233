import { Redirect } from 'react-router-dom';
import React from 'react';

const SportifProgramsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  // auth: authRoles.admin, // ['admin']
  routes: [
    {
      path: '/newspapers',
      component: React.lazy(() => import('./Newspapers')),
    },
    {
      path: '/newspapers/view',
      component: () => <Redirect to="/newspapers" />,
    },
    {
      path: '/newspapers/create',
      component: () => <Redirect to="/newspapers" />,
    },
  ],
};

export default SportifProgramsConfig;
