import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import FuseSvgIcon from '../../FuseSvgIcon';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import ContactAvatar from '../../ContactAvatar';

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  '&.active': {
    backgroundColor: theme.palette.background.default,
  },
}));

function ContactListItem(props) {
  // const { chat } = props;
  const routeParams = '7184be71-a28f-4f2b-8c45-15f78cf2f825';
  const test = props.chat;
  const chat = true;
  const contact = {
    id: '7184be71-a28f-4f2b-8c45-15f78cf2f825',
    avatar: 'assets/images/avatars/female-05.jpg',
    name: 'Alissa Nelson',
    about: "Hi there! I'm using FuseChat.",
    status: 'offline',
    details: {
      emails: [
        {
          email: 'alissanelson@mail.us',
          label: 'Personal',
        },
      ],
      phoneNumbers: [
        {
          country: 'lu',
          phoneNumber: '893 600 2639',
          label: 'Mobile',
        },
      ],
      title: 'Bindery Machine Operator',
      company: 'Emtrak',
      birthday: '1993-10-19T12:00:00.000Z',
      address: '514 Sutter Avenue, Shindler, Puerto Rico, PO3862',
    },
    attachments: {
      media: [
        'assets/images/cards/01-320x200.jpg',
        'assets/images/cards/02-320x200.jpg',
        'assets/images/cards/03-320x200.jpg',
        'assets/images/cards/04-320x200.jpg',
        'assets/images/cards/05-320x200.jpg',
        'assets/images/cards/06-320x200.jpg',
        'assets/images/cards/07-320x200.jpg',
        'assets/images/cards/08-320x200.jpg',
      ],
      docs: [],
      links: [],
    },
  };

  return (
    <StyledListItem
      button
      className="px-32 py-12 min-h-80"
      active={routeParams === contact.id ? 1 : 0}
      component={NavLinkAdapter}
      to={`/messaging/${contact.id}`}
      // end
      activeClassName="active"
    >
      <ContactAvatar contact={contact} />

      <ListItemText
        classes={{
          root: 'min-w-px px-16',
          primary: 'font-medium text-14',
          secondary: 'truncate',
        }}
        primary={contact.name}
        secondary={chat ? contact.lastMessage : contact.about}
      />

      {contact.contactId && (
        <div className="flex flex-col justify-center items-end">
          {contact?.lastMessageAt && (
            <Typography
              className="whitespace-nowrap mb-8 font-medium text-12"
              color="textSecondary"
            >
              {format(new Date(contact.lastMessageAt), 'PP')}
            </Typography>
          )}
          <div className="items-center">
            {contact.muted && (
              <FuseSvgIcon size={20} color="disabled">
                heroicons-solid:volume-off
              </FuseSvgIcon>
            )}
            {Boolean(contact.unreadCount) && (
              <Box
                sx={{
                  backgroundColor: 'secondary.main',
                  color: 'secondary.contrastText',
                }}
                className="flex items-center justify-center min-w-20 h-20 rounded-full font-medium text-10 text-center"
              >
                {contact.unreadCount}
              </Box>
            )}
          </div>
        </div>
      )}
    </StyledListItem>
  );
}

export default ContactListItem;
