 import { authRoles } from 'app/auth';
import React from 'react';

const GenderConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  // auth: authRoles.admin, // ['admin']
  routes: [
    {
      path: '/gender',
      component: React.lazy(() => import('./Gender')),
    },
  ],
};

export default GenderConfig;
