/** Lazy load organization page * */
import React from 'react';
import i18next from 'i18next';

import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'organizations', en);
i18next.addResourceBundle('fr', 'organizations', fr);

const OrganizationsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/organizations',
			component: React.lazy(() => import('./index'))
		}
	]
};

export default OrganizationsConfig;
