import { combineReducers } from '@reduxjs/toolkit';
import chats from './chatsSlice';
import chat from './chatSlice';
import contacts from './contactsSlice';
import user from './userChatSlice';
import messagings from './reducers/index';

const reducer = combineReducers({
	user,
	contacts,
	chats,
	chat,
	messagings
});

export default reducer;
