import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// PL$: Detecte Browser Language on load
const locales =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en-US';
const lang = locales.split(/[-_]/)[0];

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      'Welcome to Dosteor': 'Welcome to Dosteor application',
    },
  },
  fr: {
    translation: {
      'Welcome to Dosteor': "Bienvenue dans l'application Dosteor",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fr',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
