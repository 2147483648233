import React from 'react';
import API from '@pi/api';
import AsyncSelect from 'react-select/async';
import clsx from 'clsx';
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
	search: {},
	margin: {
		margin: theme.spacing(1),
		width: '100%',
		height: '100%'
	}
}));

function SelectAutocompletBar({ selectedValue, onChange, setInputList }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const theme = useTheme();

	const [inputValue, setValue] = React.useState('');

	// handle input change event
	const handleInputChange = value => {
		setValue(value);
	};

	// handle selection
	const handleChange = value => {
		setInputList(prevFriends => [
			...prevFriends,
			{
				idFood: value.idFood,
				food: value.food,
				code: value.code,
				calorie: value.calorie,
				quantity: 100
			}
		]);
	};

	// load options using API call
	const loadOptions = () => {
		// return axios
		//   .post('/api/food_search', {
		return API.post('/api/food_search', {
			action: 'search',
			words: inputValue
		}).then(response => {
			const options = [];
			response.data.foods.forEach(permission => {
				options.push({
					idFood: permission.idFood,
					food: permission.food,
					code: permission.code,
					calorie: permission.kcal
				});
			});
			return options;
		});
	};

	return (
		<div style={{ width: '100%' }} className="flex flex-1 z-10 items-center p-8 sm:p-12 max-w-md">
			<ThemeProvider theme={theme}>
				<FormControl className={clsx(classes.margin, 'flex')}>
					<AsyncSelect
						id="food-selected"
						placeholder="sélectionner la nourriture, Rechercher..."
						cacheOptions
						defaultOptions
						value={selectedValue}
						loadOptions={loadOptions}
						getOptionLabel={e => e.food}
						getOptionValue={e => e.idFood}
						onInputChange={handleInputChange} // onInputChange={ev => dispatch(Actions.setSearchText(ev))}
						onChange={value => onChange(value, handleChange(value))}
						// style={{ width: '100%' }}
						// isClearable
					/>
				</FormControl>
			</ThemeProvider>
		</div>
	);
}

export default SelectAutocompletBar;
