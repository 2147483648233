import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Paper, DialogTitle } from '@mui/material';
import { useSelector } from 'react-redux';
import AutoComplet from 'app/shared-components/AutoComplet';
// import * as Actions from '../store/actions';
import { useParams } from 'react-router-dom';

const ComfirmPopUp = ({
	openConfirm,
	setOpenConfirm,
	onSubmit,
	idEvent,
	patient,
	setPatient,
	patientList,
	stateEventListEvent
	// closeComposeDialog
}) => {
	// const dispatch = useDispatch();
	const userId = useSelector(({ auth }) => auth.user.id);
	const idOrganization = useSelector(({ auth }) => auth.user.idOrganization);
	useEffect(() => {}, [openConfirm]);

	const handleClickOpen = () => {
		setOpenConfirm(true);
	};

	const handleCloseRemove = () => {
		setOpenConfirm(false);
	};

	async function handleCreate() {
		onSubmit();
		await setOpenConfirm(false);
	}

	const handlePatient = setPat => {
		const { name, idPatient, phonenumber } = setPat;

		const getPatient = {
			name,
			idPatient,
			phonenumber
		};
		setPatient(getPatient);
	};

	return (
		<Paper
			sx={{
				position: 'absolute !important',
				width: '300px !important',
				height: '500px !important',
				minWidth: '100%',
				minHeight: '500px !important',
				zIndex: '999999999999999',
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				visibility: openConfirm ? 'visible' : 'hidden'
			}}
		>
			<Dialog
				open={openConfirm}
				onClose={handleCloseRemove}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEnforceFocus
				fullWidth
				className=" flex justify-center flex-col"
				sx={{
					position: 'absolute !important',
					width: '100% !important',
					height: '500px !important',
					minWidth: '100%',
					minHeight: '100% !important',
					zIndex: '999999999999999',
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column'
				}}
			>
				<DialogTitle className="text-center text-md font-bold" id="alert-dialog-title">
					Choisissez un patient
				</DialogTitle>
				<DialogContent className="text-center">
					<DialogContentText id="alert-dialog-description">
						A qui est destiné cette consultation.
					</DialogContentText>
					<div className="w-full flex justify-center items-center">
						<AutoComplet
							options={patientList}
							className=" text-ellipsis"
							label="Choix du patient"
							handleChange={handlePatient}
							setValue={setPatient}
							value={patient}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenRemove(false)}>Annuler</Button>
					<Button color="primary" onClick={e => handleCreate(e)} autoFocus>
						Enregistrer
					</Button>
				</DialogActions>
			</Dialog>
		</Paper>
	);
};

export default ComfirmPopUp;
