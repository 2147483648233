import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.user.background,
		'&.user': {
			'& .username, & .email': {
				transition: theme.transitions.create('opacity', {
					duration: theme.transitions.duration.shortest,
					easing: theme.transitions.easing.easeInOut
				})
			}
		}
	},
	avatar: {
		background: theme.palette.background.default,
		transition: theme.transitions.create('all', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut
		})
	}
}));

function UserNavbarHeader(props) {
	const classes = useStyles();
	const user = useSelector(({ auth }) => auth.user);

	const avatarImg =
		!_.isEmpty(user.data.photoURL) && user.data.photoURL !== 'NULL'
			? user.data.photoURL
			: 'assets/images/avatars/profile.jpg';

	return (
		<AppBar
			position="static"
			color="primary"
			classes={{
				root: clsx(classes.root, 'user z-99999999999999999999')
			}}
		>
			<div className="flex flex-wrap gap-12 py-24">
				<Avatar
					variant="square"
					classes={{
						root: clsx(classes.avatar, 'avatar w-68 h-68 pl-10 box-content'),
						img: 'rounded'
					}}
					alt="user photo"
					src={`${avatarImg}`}
				/>
				<div className="flex flex-col flex-1 justify-center">
					<Typography className="username text-18 whitespace-nowrap font-semibold mb-4" color="inherit">
						{user.data.displayName}
					</Typography>
					<Typography className="email text-13 opacity-50 whitespace-nowrap font-medium" color="inherit">
						{user.data.organization}
					</Typography>
				</div>
			</div>
		</AppBar>
	);
}

export default UserNavbarHeader;
