// import layout1 from 'app/fuse-layouts/layout1/Layout1';
// import layout2 from 'app/fuse-layouts/layout2/Layout2';
// import layout3 from 'app/fuse-layouts/layout3/Layout3';
import layout4
  from 'app/fuse-layouts/layout4/Layout4'; // PLS - Add Dosteor Theme

const FuseLayouts = {
  // layout1,
  // layout2,
  // layout3,
	layout4
};

export default FuseLayouts;
