/** Authorization Roles * */
const authRoles = {
  admin: ['AD'], // Role Gestionnaire
  praticienAdmin: ['PA'], // Role Praticien(ne) Administrateur
  praticienGestionnaire: ['PG'], // Role Praticien(ne) Gestionnaire
  praticien: ['PR'], // Role Praticien(ne)
  patient: ['PT'], // Role Patient(e)
  guest: ['RE'], // role Invité(e)
  developer: ['DE'], // role developer
  humaineRessouces: ['RH'], // Role Ressource Humaine
  superAdmin: ['SA'], // Role Super Administrateur
  onlyUser: ['US'], // User

  dashbord: ['AD', 'PA', 'PG', 'PR', 'PT', 'RH', 'US', 'SA', 'RE', 'DE'], // acces Dashbord
  profil: ['AD', 'PA', 'PG', 'PR', 'PT', 'RH', 'US', 'SA', 'RE', 'DE'], // acces Dashbord
  agenda: ['SA', 'AD', 'RH', 'PA', 'PR', 'PG', 'DE'], // acces agenda
  agendaTempo: ['SA', 'PA', 'PR', 'PG', 'DE'], // acces agenda
  agendaWrite: ['PA', 'PR', 'PG', 'DE'], // acces agenda RW
  agendaDelete: ['PA', 'PR', 'PG', 'DE', 'RH', 'AD'], // acces agenda delete RW
  agendaGestion: ['PG', 'RH', 'AD', 'DE'], // acces agenda Gestionnaire
  user: ['SA', 'AD', 'PA', 'PG', 'DE'], // acces Utilisateur
  userWrite: ['SA', 'AD', 'RH', 'PA', 'PG', 'DE'], // acces Utilisateur RW
  dps: ['SA', 'PT', 'PA', 'PR', 'PG', 'DE'], // acces Dossier Patient
  dpsWrite: ['PA', 'PR', 'PG', 'DE'], // acces Dossier Patient  RW
  organisation: ['SA', 'DE'], // acces Organisation
  organisationWrite: ['SA', 'DE'], // acces Organisation RW
  praticienWrite: ['PA', 'PR', 'PG', 'DE'], // acces Organisation RW
  note: ['SA', 'PT', 'PA', 'PR', 'PG', 'RH', 'DE'], // acces Note
  appointment: ['RH', 'AD', 'PT', 'DE'], // acces Appointment / Rendez-vous
  preferences: ['SA', 'AD', 'RH', 'PA', 'PR', 'PG', 'DE'], // acces Preferences
  dietetiques: ['SA', 'PA', 'PR', 'PG', 'DE'], // acces Dietetiques
  preferencesDietetiques: ['PA', 'PR', 'PG', 'DE'], // acces Preferences RW

  staff: ['SA', 'RH', 'PA', 'PG', 'PR', 'PT', 'AD', 'US', 'RE', 'DE'], // Role Maintenances RW
  onlyGuest: [],
};

export default authRoles;
