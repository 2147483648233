import { lazy } from 'react';
import { authRoles } from 'app/auth';

const NotesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
	// auth: authRoles.admin, // ['admin']
  routes: [
    {
      path: '/notes/:id?/:labelHandle?/:labelId?',
      component: lazy(() => import('./NotesApp')),
    },
  ],
};

export default NotesAppConfig;
