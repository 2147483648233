import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles({
	autocomplet: {}
});

const AutoComplet = props => {
	const classes = useStyles();

	const { options, label, value, className, handleChange } = props;
	return (
		<Autocomplete
			id="autocomplet"
			getOptionSelected={option => option.value === value.value}
			className={clsx(classes.autocomplet, className, 'flex mt-40')}
			options={options}
			onChange={(event, newValue) => {
				handleChange(newValue);
			}}
			getOptionLabel={option => option.name}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.idPatient}>
						{option.name}
					</li>
				);
			}}
			openText="Ouvrir"
			value={value}
			renderInput={params => {
				return (
					<TextField
						{...params}
						label={label}
						variant="outlined"
						// style={{
						// 	position: 'absolute'
						// }}
					/>
				);
			}}
			sx={{
				width: '50% !important',
				maxWidth: '50% !important'
			}}
			disablePortal={true}
			// ListboxProps={{ position: 'absolute !important', zIndex: '99999999999999999' }}
		/>
	);
};

export default AutoComplet;
